import React from 'react';
import { Box, Typography, Paper, Avatar, Card, Alert, CardMedia, CardContent, Grid } from '@mui/material';
import ExperienceCard from './CardComponent';

const demos = [
    {
        logo: "foto_small",
        youtube: "Q-iidnaLULA?si=GRROKrNHXic8YB85",
        title: "Intelligent CV",
        detail: "Your ultimate job search companion! Simply upload your PDF resume, and let our advanced system do the magic. Within moments, you'll receive a professional cover letter, an enhanced or translated CV, and a concise resume summary. "
    },

]


export default function MainPage() {
    return(

        <Box sx={{padding:2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >  
            <Box m={1} />

            <Grid container spacing={4}>
                {demos.map((n) => (
                <Grid item key={n.id} xs={12} sm={6} md={4}>
                    <ExperienceCard key={"nk"+n.id}  {...n}></ExperienceCard>
                </Grid>
                ))}
            </Grid>

            <Box m={1} />


        </Box>

    )
}